footer{
    background-color: #28292D;
    padding-top: 30px;
}
.content{
    color: #fff;
}
.content img{
    height: 50px;
}
.content a{
    display: inline-block;
    text-decoration: none;
    color: #fff;
}
.content h4{   
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.content h5{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
      
}
.content ul{
    list-style: none;
    padding: 0;
    margin-top: 30px;
}
.content ul li{
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
}
.content ul li::before{
    content: "";
    background-image: url("../../public/images/right_arrow.png");
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: 400;
    height: 20px;
    width: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.social_media a img{
    height: 30px;
}
.social_media a:not(:last-child){
    display: inline-block;
    margin-right: 20px;
}
.appstore{
    margin-top: 40px;
}
.appstore img{
    height: 40px;
}
.footer_copywrite{
    border-top: 2px solid #25BEC1;
    color: #FFFFFF;
    padding: 30px 0;
}
.footer_copywrite a{
    color: #FFFFFF;
    text-decoration: none;
}
@media only screen and (max-width: 960px) {
    .content_2{
        width: fit-content;
        margin: 0 auto;
        margin-right: 0;
    }
    .content img{
        margin: 0 auto;
    }
    .img_wrap{
        text-align: center;
        margin-bottom: 30px;
    }
     .social_media_wrap h4{
         text-align: center;
    }
    .social_media_wrap .social_media{
        text-align: center;
    }
    .appstore{
        text-align: center;
    }
    .footer_copywrite{
        margin-top: 30px;
        text-align: center;
    }
    .privacy{
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}
@media only screen and (max-width: 600px) {
    .content h4{  
        font-size: 16px;
        margin-bottom: 20px;
    }
    .content h5{
        font-size: 12px;
    }
    .content a{
        font-size: 12px;
    }
    .content ul{
        margin-top: 10px;
    }
    .content ul li{
        margin-bottom: 10px;
    }
    .content img{
        height: 30px;
    }
    .footer_copywrite p{
        font-size: 14px;
    }
    .footer_copywrite a{
        font-size: 14px;
        padding:0 20px;
    }
    .privacy{
        justify-content: center;
    }
}