.PrizeSliderwrap{
    text-align: center;
    margin: 120px 0;
}
.PrizeSliderwrap h3{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    color: #797F89;
    margin-bottom: 40px;

}
.slideCard{
    text-align: center;
    background: #DDF6FF;
    border-radius: 24px;
    padding: 20px;
    transform: scale(0.7);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    margin-bottom: 10px;
}

.slideCard img{
    width: 80%;
    margin: 0 auto;
}
.slideCard h6{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #28292D;
    margin-top: 10px;
}
.slideCard h5{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #4647C6;
}

@media only screen and (max-width: 600px) {
   
    .PrizeSliderwrap h3{
        font-size: 24px;
        padding: 0 15px;
    }
    .slideCard h6{
        font-size: 16px;
    }
    .slideCard h5{
        font-size: 16px;
    }
    .PrizeSliderwrap{
        margin: 60px 0;
    }
    }
