.viewmoreMobileSlider {
    margin-top: 50px;
}
.slideCard{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.slideCard img{
    width: 100%;
}
