@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700&family=Work+Sans:wght@300;400;500;600;700&amily=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  font-family: "Work Sans", sans-serif;
}
.main-container {
  width: 90%;
  margin: 0 auto;
  min-height: 100vh;
  align-items: center;
  /* max-height: 100vh;
    overflow: hidden; */
}

.mainBg {
  background: url(./assets/images/mathIQ-Background-Image.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: calc(100%) calc(100%);
  background-blend-mode: multiply;
  overflow: hidden;
}

#startScreen {
  /* background: url(./assets/images/bg_start_screen.svg); */
  background: url(./assets/images/bg_start_screen_2.svg);
  background-position: calc(100%) calc(100%);
  background-repeat: no-repeat;
  /* background-size: calc(100%) calc(100%); */
  background-size: cover;
  /* background-blend-mode: multiply; */
  overflow: hidden;
  border: 2px solid #25bec1;
}
/* container 1 & 3 */
.liga-container1,
.liga-container3 {
  width: 80%;
  border-radius: 8px;
  min-height: 410px;
}

/* container 1 */
.liga-container1 {
  margin: 0 auto 0 0;
  padding: 4rem 0;
  border: 1px solid rgba(255, 255, 255, 0.44);
  background: linear-gradient(
    153.33deg,
    rgba(126, 9, 181, 0.4048) 0%,
    rgba(40, 7, 109, 0.44) 100%
  );
  box-sizing: border-box;
  backdrop-filter: blur(29px);
  overflow: hidden;
}

.liga-container1:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-image: url(./assets/images/sparkle_1.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.bg-full-glass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/frosted-glass.png);
  background-size: cover;
  opacity: 0.15;
}

/* container 3 */
.liga-container3 {
  margin: 0 0 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.44);
  background: linear-gradient(
    153.33deg,
    rgba(126, 9, 181, 0.4048) 0%,
    rgba(40, 7, 109, 0.44) 100%
  );
  box-sizing: border-box;
  backdrop-filter: blur(29px);
}

.liga-container3 .liga-leaderboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: #9f7ccd;
  background-blend-mode: multiply;
}
.liga-container3 .liga-leaderboard-header {
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
}
.liga-container3 .liga-leaderboard-header .mathIQ-logo {
  background: #442472;
  font-weight: 500;
  font-size: 0.75rem;
  border-radius: 14px;
  padding: 1px 10px;
}
.liga-container3 .liga-leaderboard-header .mathIQ-logo span {
  color: #ffe014;
  font-size: 0.9rem;
  font-weight: 600;
}
.liga-container3 .liga-leaderboard-data .school-name {
  color: #fff;
  font-size: 9px;
  font-weight: 700;
}
.liga-container3 .liga-leaderboard-data .stu_name-city {
  color: #7b9ac9;
  font-size: 7.5px;
  margin: 0;
}
.liga-container3 .liga-leaderboard-data .school-rate {
  color: #f25bcb;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: flex-start;
  margin: 0;
  margin-left: 5px;
}
.liga-leaderboard-data .stu_rank {
  color: #ef508a;
  font-size: 9px;
  font-weight: 700;
  text-align: right;
  margin: 0;
  margin-right: 4px;
}

.liga-leaderboard-data .your-max-rank {
  background: #2ead6e99;
  border-radius: 7px;
  padding: 5px 0px;
  background-blend-mode: multiply;
}

/* .startScreen_container {
  position: relative;
  z-index: 1;
  height: 100%;
  background: #432967;
  border-radius: 8px;
} */

/* .home_header { */
/* height: 94%; */
/* background: linear-gradient(
    180.07deg,
    #310142 12.54%,
    rgba(82, 0, 102, 0) 99.94%
  ); */
/* border-radius: 8px; */
/* } */
.startScreen_container h1 {
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18.9222px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
}
.startScreen_container h1.welcome_user {
  bottom: 34%;
}
.tagline_wrap {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  background: #080342;
  border-radius: 4px;
  padding: 7px 13px;
  max-width: 239px;
  width: 100%;
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform: translateX(-50%);
}
.header_content_wrap {
  position: relative;
}
.logo_wraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* margin-bottom: 40px; */
  /* background: url(./assets/images/Sparkle-bg.svg); */
  background-position: 0 2rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 9;
  padding-top: 30px;
  padding-bottom: 20px;
}
.logo_wraper h5 {
  margin-top: 10px;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17.5204px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.timeer_home_img_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.timeer_home_img_wrap h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 1;
  text-align: center;
  color: #fff;
}
.timeer_home_img_wrap p {
  color: #c8aad8;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 80px 0 0 0;
  text-transform: uppercase;
  font-size: 10px;
}

.game_payed {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.game_payed .game_payed_item {
  height: 28px;
  width: 28px;
  background: linear-gradient(180deg, #400056 11.76%, #62126f 87.5%);
  display: flex;
  align-items: center;
  color: #fdd854;
  justify-content: center;
  margin: 4px 1.5px 5px;
  /* box-shadow: 1px 1px 1px rgb(255 255 255 / 30%); */
  font-family: "digitalNum";
  border: 0.7px solid;
  border-image-slice: 30%;
  border-image-source: linear-gradient(140.39deg, #3d0052 21.37%, #eab5fd 100%);
  border-radius: 5px;
}

.slider_text {
  position: relative;
  z-index: 99;
  padding-top: 9px;
}

.slider_text h5:first-child {
  color: #fdd854;
  margin: 0;
  text-align: center;
  font-family: "Baloo 2", cursive;
  font-weight: 600;
}
.play_btn_wraper {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  /* margin-top: 70px; */
  bottom: 27%;
  left: 50%;
  transform: translateX(-50%);
}

.startbtn {
  color: #fff;
  /* background-image: url(./assets/images/startbtn-image.png); */
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0;
}

.wave_img_1 {
  width: 100%;
  margin-top: -92%;
  display: none;
}

.foore_icons {
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  bottom: 5px;
  /* display: none; */
}
.foore_icons .icons_itesm {
  margin: 0 5px;
}
.foore_icons .icons_itesm img {
  cursor: pointer;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

#startScreen {
  position: relative;
  /* background-color: #432967; */
  /* height: 90vh; */
  max-width: 326px;
  width: 100%;
  min-height: 562px;
  margin: 0 auto;
  border-radius: 8px;
}

#startScreen::before {
  content: "";
  background-image: linear-gradient(180deg, #310142, rgba(82, 0, 102, 0));
  height: 342px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  border-radius: 8px;
  display: none;
}

.mark-distribution p {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  margin: 0;
  padding: 8px 0;
}

.mark-distribution .your-score-range-bg {
  background: #2ead6e;
}
.startScreen .mark-distribution .your-score-range-bg {
  background: none;
}
#resultScreen {
  position: relative;
  color: #fff;
  /* background: linear-gradient(
    180deg,
    #703480 -14.77%,
    #bd3a60 66%,
    #3c67e0 110.25%,
    #432967 30%
  ); */
  background: linear-gradient(
    180deg,
    #703480 -14.77%,
    #bd3a60 33%,
    #113072 102.25%,
    #432967 2%
  );

  max-width: 326px;
  width: 100%;
  min-height: 562px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid #25bec1;
}
.custum_share_modal .modal-content {
  border-radius: 0.8rem !important;
  background: linear-gradient(
    180deg,
    #843678 0%,
    #f5657f 24.55%,
    #651861 91.89%
  );
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-family: "Work Sans", sans-serif;
}
.custum_share_modal .centerBand_btn_wrap {
  position: relative;
}

.custum_share_modal .modal-header {
  padding: 24px 24px 20px 24px;
}
.custum_share_modal button.close {
  position: absolute;
  top: 0;
  right: 15px;
  background: none;
  border: none;
  width: auto;
}
.custum_share_modal .modal-dialog {
  max-width: 300px;
  margin: 0 auto;
}
.custum_share_modal .modal_score_title {
  font-size: 20px;
  font-weight: 900;
}
.custum_share_modal .modal_score_title img {
  height: 30px;
}
.custum_share_modal .model_score {
  font-size: 25px;
  font-weight: 900;
  color: #ffd872;
}
.custum_share_modal .icon_all {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.custum_share_modal #facebook {
  background-image: url(./assets/images/facebook.png);

  background-repeat: no-repeat;
  background-size: 100%;
}

.custum_share_modal #instagram {
  background-image: url(./assets/images/instagram.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.custum_share_modal #whatsapp {
  background-image: url(./assets/images/whatsapp.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.custum_share_modal #linkedIn {
  background-image: url(./assets/images/linkedIn.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
#quizScreen .topbrand,
#resultScreen .topbrand {
  position: relative;
  height: auto;
  padding-bottom: 25px;
}
#resultScreen .topbrand {
  padding-bottom: 0;
}
/* #quizScreen .topbrand::before {
    content: '';
    position: absolute;
    background: url(./assets/images/top-green-bg.svg);
    background-size: 101% 100%;
    background-repeat: no-repeat;
    height: 200%;
    width: 100%;
    overflow: hidden;
    top: -26%;
} */

#quizScreen {
  background: url(./assets/images/game_screen_bg.svg);
  background-position: top;
  background-repeat: no-repeat;
  /* background-size: calc(100%) calc(100%); */
  background-size: cover;
  /* background-blend-mode: multiply; */
  overflow: hidden;
  /* background: linear-gradient(
      180deg,
      #b2cf6b,
      #59bda0 20%,
      #00acd6 32%,
      #043373 54%,
      #9a2076
    )
    0 0; */

  color: #fff;
  border-radius: 8px;
  /* min-height: 90vh;
  margin: 0 auto; */
  max-width: 326px;
  width: 100%;
  min-height: 562px;
  margin: 0 auto;
  display: block !important;
  border: 2px solid #25bec1;
}
#quizScreen .logo_wrap {
  position: relative;
  z-index: 9;
  padding: 8px 0 8px;
  margin-top: 12px;
}
#resultScreen .logo_wrap {
  position: relative;
  z-index: 9;
  padding: 8px 0 8px;
}
#quizScreen .back_btn,
#resultScreen .back_btn {
  height: 24px;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#quizScreen .scorebase {
  height: auto;
  /* border-top: 3px solid #6fd7e1;
    border-bottom: 3px solid #4ca8cb; */
  width: 100%;
  left: 50%;
  position: relative;
  left: tr;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url(./assets/images/scorebase.png);
  background-repeat: no-repeat;
  background-size: calc(101%) calc(100%);
}

#quizScreen .scorebase .timeAndScoreMain {
  position: relative;
  height: 90px;
  /* background: transparent linear-gradient(180deg,#66296f,#803579 35%,#bc3a61) 0 0 no-repeat padding-box; */
}

#quizScreen .scorebase .timeAndScoreMain .timmer {
  position: absolute;
  top: 50%;
  left: 10%;
  height: 120px;
  width: 120px;
  background: url(./assets/images/timerbg.png);
  background-size: calc(100%) calc(100%);
  transform: translateY(-50%);
}

/* #quizScreen .scorebase .timeAndScoreMain .timmer::before {
    content: "";
    position: absolute;
    width: 130px;
    height: 130px;
    left: 47%;
    top: 47%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background: hsla(0,0%,100%,.2) 0 0 no-repeat padding-box;
    border-radius: 50%;
} */

/* #quizScreen .scorebase .timeAndScoreMain::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 25%;
    background: url(./assets/images/middle-score-blue-line-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
} */

#quizScreen .scorebase .timeAndScoreMain .timmer_wrap {
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  background: #060964;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9;
  left: 47%;
  top: 47%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  list-style: 1;
}

#quizScreen .scorebase .timeAndScoreMain .timmer_wrap .counterTimer {
  line-height: 1;
  font-size: 1rem;
}
#quizScreen .scorebase .timeAndScoreMain .timmer_wrap span {
  font-size: 10px;
}

#quizScreen .scorebase .timeAndScoreMain .wrapper,
#quizScreen .scorebase .timeAndScoreMain .wrapper svg {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 30%;
  top: 15%;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: translate(-50%, -50%) rotate(136deg);
  transform: translate(-50%, -50%) rotate(136deg);
}
.wrapper svg,
circle {
  width: 70px;
  height: 70px;
}
circle:first-child {
  stroke: #77c47e;
  stroke-dasharray: 256;
  stroke-dashoffset: 20;
  stroke-width: 30px;
  transform: translate(15px, 15px);
  fill: none;
}

#quizScreen .timeAndScoreMain .counter_text {
  position: absolute;
  top: 50%;
  right: 0;
  width: 50%;
  justify-content: flex-end;
  padding-right: 20px;
  display: flex;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#quizScreen .scorebase .counter_text .correctCount {
  color: #47ff90;
  text-align: left;
  padding-right: 20px;
  position: relative;
}
#quizScreen .scorebase .counter_text .incorrectCount {
  color: #f77;
}
#quizScreen .scorebase .counter_text .correctCount::after {
  content: "";
  position: absolute;
  right: 10px;
  width: 1px;
  height: 100%;
  background: hsla(0, 0%, 89.4%, 0.3607843137254902);
}
#quizScreen .scorebase .counter_text .correct_incorrect {
  display: flex;
  align-items: center;
  font-weight: 900;
}

#quizScreen .scorebase .counter_text .scoreMain {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#quizScreen .scorebase .counter_text .scoreMain .scoreText {
  color: #fff;
  font-size: 13px;
}
#quizScreen .scorebase .counter_text .scoreMain .scoreCounter {
  text-align: center;
  font-size: 23px;
  line-height: 1;
}

#quizScreen .instText {
  text-align: center;
  font-size: 20px;
  margin: 1.5rem 0 12px 0;
  font-family: "Montserrat", sans-serif;
}

#quizScreen .question {
  position: relative;
  text-align: center;
  font-size: 23px;
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 23, 88, 0.4);
}
#quizScreen .question:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 23, 88, 0.4);
  position: absolute;
  left: 0;
  top: 0;
}
#optiontext {
  position: relative;
}
#optiontext .optio_container {
  margin-top: 20px !important;
  min-height: 158px;
}
#quizScreen .container-fluid .optMainH {
  padding: 8px !important;
  cursor: pointer;
}
#quizScreen .container-fluid .optMainH .options {
  height: 70px;
  border-radius: 6px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    180deg,
    #0a64cc,
    rgba(141, 62, 231, 0.8588235294117647)
  );
}
#quizScreen .container-fluid .optMainH .options.correctColor {
  background: linear-gradient(
    180deg,
    #00cd5f 0%,
    rgba(0, 179, 100, 0.859) 100%
  );
}
#quizScreen .container-fluid .optMainH .options.IncorrectColor {
  background: linear-gradient(180deg, #cd3100 0%, rgba(179, 0, 0, 0.859) 100%);
}

#quizScreen .option-unclick {
  pointer-events: none;
}

#quizScreen .restartbtn,
.skipbtn {
  width: 60px;
  outline: none;
  cursor: pointer;
  margin-bottom: 4px;
}

.boxes-bg {
  background: url(./assets/images/Boxes-bg.svg);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 2rem 1rem;
}

.logo_wrap {
  position: relative;
  z-index: 9;
  padding: 20px 0 8px;
}
.back_btn {
  height: 24px;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.title_math {
  font-size: 20px;
  position: relative;
  z-index: 9;
  padding: 15px 0;
  font-weight: 600;
}
.title_math img {
  height: 30px;
}
#resultScreen .centerBand {
  padding-bottom: 30px;
}
.centerBand_container {
  position: relative;
  margin-bottom: -6px;
}
.centerBand_container_wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0;
}
.center_score_content {
  padding-top: 0;
}
.center_score_content:before {
  content: "";
  width: 100%;
  left: 0;
  top: -105px;
  height: 100%;
  background-position: 50%;
  background-position-y: 12px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url(./assets/images/rays-bg.svg);
  z-index: -1;
}
.centerBand_btn_wrap {
  padding: 0px 15px;
}
.centerBand_btn_wrap .share_social_media_icon,
.centerBand_btn_wrap #reloadIcon {
  width: 30%;
}
.centerBand_btn_wrap #reloadIcon .scrore_count {
  display: flex;
  justify-content: center;
  width: 30%;
}
#t5 {
  color: #fff;
  font-size: 29px;
  background: #442472;
  border: 2px solid #f2bfff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  background-color: #442472;
  color: #ffe014;
  font-family: Work Sans;
  font-weight: 800;
  font-size: 32px;
  width: 78px;
  height: 78px;
}
.score_disc {
  position: relative;
  margin-top: 18px;
  z-index: 1;
}
.score_disc .score_disc_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px !important;
  padding: 5px 1rem;
  font-size: 15px;
}
.score_disc .score_disc_item.active {
  background: #f57d7d;
  font-weight: 700;
}
.score_disc .score_disc_item .item_1 {
  width: 38%;
  text-align: left;
}
.score_disc .score_disc_item .item_2 {
  width: 58%;
  text-align: left;
}
.wave_img_result {
  position: absolute;
  left: 0;
  width: 100%;
  top: 53%;
}
#resultScreen .foore_icons {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.bottom_card {
  width: 100%;
  height: 118px;
  background: rgba(21, 0, 82, 0.26);
  border-radius: 20px;
  padding: 20px;
  position: relative;
  z-index: 9;
  text-align: center;
}
.bottom_card .bottom_card_content h5.toptitle {
  font-size: 15px;
  padding: 0;
  margin-bottom: 15px;
}
.bottom_card img {
  height: 30px;
}
.resultScreen .container {
  /* margin-top: -37px; */
  position: relative;
  padding-bottom: 30px;
  z-index: 9;
  height: 100%;
  /* background: linear-gradient(
0deg
, #2d286d, #47377a); */
}
.resultScreen .container img {
  height: 40px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.startScreen_wrap {
  border-radius: 10px;
}
@media (max-width: 992px) {
  #quizScreen .container-fluid .optMainH .options {
    height: 50px;
  }
  /* .timeer_home_img_wrap img {
    height: 73px;
  } */
  .mainBg {
    background-image: url(./assets/images/mathIQ-Background-Image_mobile.png);
  }
  .main-container {
    width: 100%;
    margin: auto;
    min-height: 100vh;
  }

  #quizScreen,
  #startScreen,
  #resultScreen {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    max-height: unset;
    /* min-height: auto; */
    overflow: hidden;
  }
  #resultScreen .topbrand {
    padding: 0;
  }
  #t5 {
    height: 70px;
    width: 70px;
  }
  .startScreen_wrap,
  #startScreen,
  .quizScreen_wrap,
  .resultScreen_wrap {
    min-height: 562px;
    margin: auto;
    height: 100%;
  }
  .play_btn_wraper {
    margin-top: 89px;
  }
  #resultScreen {
    height: fit-content;
    min-height: 500px;
  }
  .home_header {
    border-radius: 0px;
  }
  .main-container {
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 100px;
  }
  /* .startScreen_container{
    height: fit-content;
  } */
  .liga-container1,
  .liga-container3 {
    width: 100%;
    margin: 0;
    min-height: 410px;
  }
  .liga-container3_wrap {
    display: none;
    padding: 0;
    margin-top: 20px;
  }
  .liga-container3 {
    display: none;
  }
  .startbtn {
    height: 60px;
  }
  .wave_img_1 {
    margin-top: -102%;
  }

  .liga-container3 .liga-leaderboard-header p {
    font-size: 17px;
  }
  .liga-container3 .liga-leaderboard-data .school-name {
    font-size: 13px;
  }
  .liga-container3 .liga-leaderboard-data .stu_name-city {
    font-size: 12px;
  }
  .liga-container3 .liga-leaderboard-data .school-rate {
    font-size: 13px;
  }
  .liga-leaderboard-data .stu_rank {
    font-size: 13px;
    margin-right: 20px;
  }
  .liga-leaderboard-data img {
    width: 50% !important;
    margin-right: 10px;
  }
  .liga-container3 .liga-leaderboard-data .school-rate img {
    width: 10px !important;
    margin: 0;
  }
  #quizScreen .topbrand img {
    height: 35px;
  }
  #quizScreen .back_btn,
  #resultScreen .back_btn {
    height: 20px !important;
  }
  .gameScreen .liga-container3_wrap,
  .resultScreen .liga-container3_wrap {
    display: none;
  }
  #startScreen {
    min-height: 562px;
  }
  #startScreen .foore_icons {
    padding-bottom: 0;
    bottom: 15px;
  }
  #quizScreen.row {
    display: block !important;
    /* min-height: 562px; */
  }
  .quizScreen_wrap {
    align-self: flex-start !important;
  }
  /* .avtar_lead_img {
    max-width: 90px;
  } */
  #resultScreen .centerBand {
    padding-bottom: 25px;
  }
  .resultScreen .container {
    padding-bottom: 25px;
  }
}
@media (max-width: 768px) {
  #quizScreen,
  #startScreen,
  #resultScreen {
    height: auto;
    width: 90vh;
    max-width: 100%;
    min-height: 560px;
    /* margin: 5vh auto; */
    /* position: relative;
    left: 50%;
    transform: translateX(-50%); */
  }
  .startScreen_wrap,
  #startScreen,
  .quizScreen_wrap,
  .resultScreen_wrap {
    height: 80vh;
    width: 90vw;
    max-width: 100%;
    min-height: 560px;
  }
}

.top_bg_img_desck {
  max-width: 100px;
}

@media (min-width: 767px) {
  #quizScreen,
  #startScreen,
  #resultScreen {
    height: 610px;
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
    /* position: relative;
    left: 50%;
    transform: translateX(-50%); */
  }
  .tagline_wrap {
    bottom: 12.5%;
  }
  .liga-container1,
  .liga-container3 {
    width: 236px;
    min-height: 100%;
  }
  .liga-container1 {
    padding-top: 25px !important;
    padding-bottom: 35px !important;
  }
  .liga-container1 .row {
    padding-left: 12px !important;
    padding-right: 6px !important;
  }
  .liga-leaderboard-data {
    padding: 10px 0 !important;
    padding-bottom: 0 !important;
  }
  .liga-leaderboard-data .row {
    margin-bottom: 6px !important;
  }
}

.btn-primary {
  color: #fff;
  background-color: #25bec1;
  border-color: #25bec1;
  font-weight: 700;
}
.btn-primary:hover,
.btn-primary:active {
  background-color: #25bec1;
  border-color: #25bec1;
}
.btn-light {
  background-color: #fff f;
  border-color: #25bec1;
}
.btn-light:hover,
.btn-light:active {
  background-color: #fff f;
  border-color: #25bec1;
}
.text_yellow {
  color: #fbd655;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  margin: 15px 0;
}
.text_light {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  color: #fff;
}
.PhoneInput .PhoneInputCountry {
  padding-right: 5px;
  border-right: 2px solid #d6d6d6;
}
.PhoneInput input {
  border: 0;
  outline: 0;
}
.passcodeModal .otp_input {
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
}
.passcodeModal .otp_input .form-group {
  width: 70%;
  margin: 0 auto;
}
.passcodeModal .modal-body {
  overflow: hidden;
}
.passcodeModal .model_head img {
  height: 100px;
}
.passcodeModal .model_head h4 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  text-align: center;
  color: #073881;
  margin: 15px 0;
}
.passcodeModal .otp_input p {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #2e2e2e;
}
.passcodeModal .result_details {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border: 2px solid #073881;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
}
.passcodeModal .result_details h5 {
  font-size: 16px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  color: #2e2e2e;
  text-align: center;
}
.passcodeModal .result_details p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Work Sans", sans-serif;
  color: #2e2e2e;
  margin-bottom: 5px;
}
.passcodeModal .result_details p:last-child {
  margin: 0;
}
.passcodeModal .result_details p span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Work Sans", sans-serif;
  color: #073881;
}
.passcodeModal .bottom_content {
  padding: 20px 0;
}
.passcodeModal .bottom_content p {
  margin: 0;
}
.passcodeModal .bottom_content p:first-child {
  text-align: center;
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 500;
  font-family: "Work Sans", sans-serif;
}
.passcodeModal .bottom_content p:last-child {
  text-align: center;
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 400;
  font-family: "Work Sans", sans-serif;
}
.passcodeModal .bottom_content .btn-primary {
  margin-top: 20px;
  padding: 8px 40px;
}
.passcodeModal span.required {
  color: red;
}
.passcodeModal .help-block {
  font-size: 10px;
  line-height: 12px;
  color: #409fd7;
}
.passcodeModal .form-group label {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #797f89;
}
.vecter_img {
  position: absolute;
}
.vecter_img.yellow_img {
  top: 0;
  left: 0;
  height: 100px;
}
.vecter_img.green_img {
  top: 145px;
  right: -30px;
  height: 130px;
}
.vecter_img.light_green_img {
  bottom: -25px;
  height: 60px;
  left: 30px;
}
.leaderboard_container {
  width: 100%;
  margin: 0 auto;
}

.leaderboard_container.leaderboard_bg {
  background-image: url(./assets/images/mathIQ-Background-Image.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: multiply;
  overflow: hidden;
}
@media (max-width: 992px) {
  .leaderboard_container.leaderboard_bg {
    background-image: url(./assets/images/mathIQ-Background-Image_mobile.png);
  }
}
.leaderboard_container.leaderboard_bg .leaderboard {
  min-height: 100vh;
}
.leaderboard_container.leaderboard_bg .leaderboard .leaderboard_head {
  padding-top: 30px;
}
.leaderboard_container.leaderboard_bg .leaderboard .leaderboard_list_wrap {
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable {
  color: #fff;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable
  .head
  .row {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  padding: 8px;
  text-align: center;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable
  .head
  .row
  div[class^="col"]:nth-child(2) {
  text-align: left;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable
  .tbody
  .row {
  background: linear-gradient(
    153.33deg,
    rgba(29, 7, 163, 0.4048) 0%,
    rgba(3, 6, 84, 0.44) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.44);
  backdrop-filter: blur(29px);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 10px;
  text-align: center;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable
  .tbody
  .row:nth-child(1) {
  background: linear-gradient(
    153.33deg,
    rgba(255, 214, 0, 0.4048) 0%,
    rgba(233, 154, 0, 0.44) 100%
  );
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable
  .tbody
  .row:nth-child(2) {
  background: linear-gradient(
    153.33deg,
    rgba(181, 181, 181, 0.4048) 0%,
    rgba(74, 73, 75, 0.44) 100%
  );
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable
  .tbody
  .row:nth-child(3) {
  background: linear-gradient(
    153.33deg,
    rgba(163, 82, 7, 0.4048) 0%,
    rgba(84, 32, 3, 0.44) 100%
  );
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .divtable
  .tbody
  .row
  div[class^="col"]:nth-child(2) {
  text-align: left;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .bottom_content {
  margin-top: 30px;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .bottom_content
  h5 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fff;
}
.leaderboard_container.leaderboard_bg
  .leaderboard
  .leaderboard_list_wrap
  .bottom_content
  .mdvgoLogo {
  height: 30px;
  margin: 0 auto;
}
/* video player */
.video-react .video-react-big-play-button {
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-image: url(./assets/images/Play.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  background-color: transparent !important;
  border: none !important;
}
.video1 {
  width: 100%;
}
.video-react .video-react-big-play-button:before {
  display: none;
}

.geniusslider .swiper-wrapper {
  align-items: stretch;
}
.geniusslider .swiper-wrapper .swiper-slide {
  height: auto;
}
.geniusslider .swiper-wrapper .swiper-slide .leaderboard_container {
  height: 100%;
}
.geniusslider .swiper-wrapper .swiper-slide .video_wraper {
  position: relative;
}
.geniusslider .swiper-wrapper .swiper-slide .btn_play {
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.geniusslider .swiper-wrapper .swiper-slide .btn_play:hover {
  cursor: pointer;
}

.full-video-slide.active {
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}
.leaderboard_list_wrap .bottom_content .mdvgoLogo {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
.leaderboard_list_wrap .bottom_content {
  padding-bottom: 60px;
}

.RegisterForm_container {
  background-image: url("./assets/images/registerform_2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 100px 0;
  font-family: "Poppins", sans-serif;
}
.recent_heihglightForm,.prizegoseto_wrap {
  background-image: url("./assets/images/recentheighlight_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 100px 0;
  font-family: "Poppins", sans-serif;
}
.recent_heighlight_header .video_player {  
  padding: 0 100px;
}
.recent_heighlight_header .video_player h1 { 
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;  
  color: #4647C6;
  margin-bottom: 40px;
} 
.crad_img_wrap img{
  width: 100%;
  box-shadow:0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-repeat: no-repeat;
  /* background-image: url(../public/images/droparroe.png); */
}
.recent_heighlight_header .event_plan {  
  margin: 0 100px;
  padding: 20px;
  margin-top: 40px;
  border-top: 1px solid #E3E3E5;
  border-bottom: 1px solid #E3E3E5;
  
}

.recent_heighlight_header .event_plan .row {
  align-items: center;
}
.vectore_icons_elm img{
  width: 60px;
  position: absolute;
  bottom: 0;
}
.vectore_icons_elm img.plus_icon{
  left:0;
  top: 45%;
  transform: translateY(50%);
}
.vectore_icons_elm img.divi_icons{
  right: 0;
  top: 0;
}
.vectore_icons_elm img.circle_icon{
  right: 0;
  bottom: 10%;
}
.recent_heighlight_header .video_player {  
  position: relative;
}
.recent_heighlight_header .event_plan .content h5 {  
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #595C62;
  
}
.recent_heighlight_header .event_plan .content h5 span {  
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
}
.recent_heighlight_page .video_player video {  
  width: 100%;
}
.recent_heighlight_page .masterofmath{
  margin: 80px 0;
}
.recent_heighlight_page .content h3{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #28292D;
  margin: 0;
}
.recent_heighlight_page .content p{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #595C62;
  margin: 0;

}


.recent_heihglightForm .row.align-items-center{
  flex-direction: row-reverse;
  
}
main.geniushuntApp{
  overflow-x: hidden;
}
.recent_heighlight_page .slider_wrap .slide_img img{
  width: 100%;
}
.btn_particepent {
  background: #4647c6;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 60px;
  display: inline-block;
  color: #fff;
  padding: 10px 40px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.container_elm {
  padding-left: 120px;
}
.container_elm_2 {
  padding: 0 120px;
}
.angry-grid {
  display: grid;
  grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
  gap: 10px;
}
/* image popup model */
.imgpopup .modal-header img{
  filter: brightness(10);
}
.imgpopup .modal-content{
  background-color: transparent;
  border: 0;
}
.imgpopupBackgroup.modal-backdrop{
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  opacity: 1;
}
.imgpopup .modal-content .modal-body img{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.angry-grid * {
  border-radius: 4px;
  overflow: hidden;
}
#item-0 {
  /* background-color: #CFDD6B;  */
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
#item-1 {
  /* background-color: #769EBB;  */
  grid-row: 1 / 2;
  grid-column: 2 / 4;
}
#item-2 {
  /* background-color: #8CFC7A;  */
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}
#item-3 {
  /* background-color: #979BDE;  */
  grid-row: 2 / 3;
  grid-column: 1 / 3;
}
#item-4 {
  /* background-color: #DEE8BD;  */
  grid-row: 3 / 4;
  grid-column: 3 / 4;
}
#item-5 {
  /* background-color: #7DB5DB;  */
  grid-row: 3 / 4;
  grid-column: 1 / 3;
}
.swiper-slide-active .slideCard {
  transform: scale(1);
}
.masterofmath_wrap .slider_wrap{
  overflow: hidden;
  position: relative;
}
.blog_slider {
  position: relative;
  overflow: hidden;
}
.masterofmath_wrap .slider_wrap .swiper::after,
.blog_slider.swiper::after,
.blog_slider.swiper::before
{
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  height: 100%;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  z-index: 1;
}
.blog_slider.swiper::before{
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  right:auto;
  left: 0;
  top: 0;
  z-index: 2;
}
.masterofmath_wrap .slider_wrap .swiper-button-prev, 
.masterofmath_wrap .slider_wrap .swiper-button-next,
.blog_slider  .swiper-button-prev, 
.blog_slider .swiper-button-next{
  top: 72%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.blog_slider  .swiper-button-prev, 
.blog_slider .swiper-button-next{
  top: 50%;
}
.slider_wrap .swiper-button-prev,
.blog_slider .swiper-button-prev{
  background-image: url(../public/images/prev_arrow_icon.svg);
  left: 43%;
}
.blog_slider .swiper-button-prev{
  left: 0;
}
 .slider_wrap .swiper-button-next,
 .blog_slider .swiper-button-next{
  background-image: url(../public/images/next_arrow_icon.svg);
  right: auto;
  left: 47%;
}
.blog_slider .swiper-button-next{
  left: auto;
  right: 0;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after,.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  display: none;
}
.blog_slider .swiper-button-next.swiper-button-disabled,
.blog_slider  .swiper-button-prev.swiper-button-disabled{
  display: none
}
.masterofmath_wrap .slider_wrap .content{ 
  position: absolute;
  left: 43%;
  width: 100%;
  top: 77%;
  transform: translateY(-50%);

}
.masterofmath_wrap .slider_wrap .swiper{
  overflow: visible;
}
.masterofmath_wrap .slider_wrap h4{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #4647C6;
  margin-bottom: 0;
  padding-left: 100px;
  
}
.masterofmath_wrap .student_name{
  border-top: 1px solid #E3E3E5;
  border-bottom: 1px solid #E3E3E5;
  padding: 15px 0;
  margin-top: 15px;
}
.masterofmath_wrap .student_name h5{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #595C62;
  margin: 0;
  }
  .masterofmath_wrap .col-sm-7{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .video_wrap_elm{
    position: relative;
  }
  .video_wrap_elm .btn_play_video{
    width: 80px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }
  .video_wrap_elm .btn_play_video img{
    width: 100%;
  }
.modal-header {
  border: 0;
  justify-content: end;
  padding-bottom: 0;
}
.registerPopup section .formWrapelm {
  padding: 0;
}
.registerPopup section .formWrapelm .mt-5 {
  margin-top: 20px !important;
}
.registerPopup .form-check-input[type="checkbox"] {
  width: 20px;
  max-width: 20px;
  height: 20px;
  border-radius: 0;
  border-color: #595c62;
  margin-right: 15px;
  cursor: pointer;
}
.registerPopup .form-check-input:checked {
  background-color: #4647c6;
  border-color: #4647c6;
}
form .text.text-danger{
  margin-top: 30px;
}
.model_content .input-group{
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}
.input-group .form-control{
  border-left-color: #ffffff;
}
.input-group .input-group-text{
  position: relative;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-right-color: #ffffff;
  padding-left: 25px;
  background-image: url(../public/images/droparroe.png);
  background-position-y: center;
  background-position-x: 10px;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size:10px;
  color: #B4BBC6;
}
.input-group .input-group-text::before{
  content: '';
  position: absolute;
  width: 2px;
  height: 80%;
  right: 0;
  background-color: #ced4da;
  top: 50%;
  transform: translateY(-50%);
  
}
/* .swiper-backface-hidden .swiper-slide{
  width: 20%;
} */

@media only screen and (max-width: 1000px) {
  .container_elm {
    padding-left: 50px;
  }
  .container_elm_2 {
    padding: 0 50px;
  }
}
@media only screen and (max-width: 960px) {
  .model_content{
    max-width: 350px;
    margin: 0 auto;
  }
  .masterofmath_wrap .slider_wrap .content{
    top: 81%;
  }
  .masterofmath_wrap .slider_wrap .swiper-button-next{
    left: 50%;
  }
  .recent_heihglightForm, .prizegoseto_wrap{
    background-size: contain;
  }
  .recent_heighlight_page .content h3{
    font-size: 30px;
  }
  .recent_heighlight_page .crad_img_wrap img{
    margin-top: 15px;
  }
  .recent_heighlight_page .masterofmath{
    margin: 50px 0;
  }
  
  .RegisterForm_container{
    padding: 50px 0;
  }
  .input-group .input-group-text{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .container_elm {
    padding-left: 20px;
  }
  .container_elm_2 {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 960px) {
  .recent_heighlight_header .event_plan{
    margin: 0 50px;
  }
  .vectore_icons_elm img{
    width: 20px;
  }
  
  .recent_heighlight_header .video_player {  
    padding: 0 40px;
  }
  .registerPopup .form-check {
    text-align: center;
    font-size: 13px;
  }
  .registerPopup .form-check-input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }
}
@media only screen and (min-width: 600px) {
  .geniushuntApp .swiper-slide.swiper-slide-active{
    width: 40%!important;
  }
}
@media only screen and (max-width: 600px) {
  .masterofmath_wrap .slider_wrap .swiper::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    z-index: 2;
  }
  .masterofmath_wrap .slider_wrap .swiper-button-next{
    left: auto;
    right: 0;
  }
  .geniushuntApp .swiper-slide.swiper-slide-active{
    border: 2px solid #4647C6;
    border-radius: 6px;
  }
  .masterofmath_wrap .student_name{
    margin: 15px 0;
  }
  .masterofmath_wrap .slider_wrap .content{
    position: relative;
    top: auto;
    left: 0;
    transform: none;
    padding-top: 15px;
    text-align: center;
  }
  .masterofmath_wrap .slider_wrap .swiper-button-prev{
    
    left: auto;
  }
  .masterofmath_wrap .slider_wrap h4{
    padding-left: 0;
    font-size: 16px;
  }
  .recent_heighlight_header .video_player h1{
    font-size: 30px;
    margin-bottom:  20px;
  }
  .video_wrap_elm .btn_play_video{
    width: 45px;
  }
  .RegisterForm_container{
    background-size: contain;
    margin-top: 50px;
    padding: 20px 0;
  }
  .masterofmath_wrap .col-sm-7{
    flex-direction: column-reverse;
  }
  
  .recent_heighlight_header .event_plan{
    margin: 20px 0;
  }
}
