.ModelPopup h3{  
    font-weight: 600;
    font-size: 30px;  
    text-align: center;    
    color: #28292D;
    margin-bottom: 40px;
}   
.ModelPopup h5{
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #585858;
    margin-bottom: 20px;
}
.ModelPopup p{
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #585858;
}
.ModelPopup input{
    max-width: 300px;
    margin: 0 auto;
    height: 40px;
}

.ModelPopup input::placeholder{   
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #B4BBC6;
}
.btnClose{
    cursor: pointer;
   
}
.btnClose img{
    width: 30px;
}

button{
    max-width: 250px;
    display: block;
    margin: 0 auto;
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 20px;
    
}
button:disabled{
    opacity: 0.5;
    pointer-events: none;
}
.img_wrap{
    text-align: center;
}
.ModelPopup img{
    max-width: 100px;
    margin: 0 auto;
}
.ModelPopup section{
    padding:0  20px;
    margin: 0;
}
.ModelPopup section img{
    width: 100%;
    max-width: 100%;
}
.ModelPopup section h3 {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
}
.ModelPopup section input{
    max-width: inherit;
}
.ModelPopup section h4 {
    font-size: 20px;
    margin: 0;
}
@media only screen and (max-width: 960px) {
    .ModelPopup section h4 {
        display: inline;
    }
    .ModelPopup section h3 {
        text-align: center;
        display: inline-block;
        margin-left: 8px;
    } 
    .ModelPopup section{
        padding-top: 90px;
    }
    .ModelPopup section img{
        width: 100%;
        max-width: 150px;
    }
    
    
}


@media only screen and (max-width: 600px) {
    .ModelPopup section h4 {
        font-size: 16px;
      }
      .ModelPopup section h3 {
        font-size: 16px;
      }
      .ModelPopup section{
        padding-top: 75px;
      }
}