.NextCity {
  padding: 100px 0;
  font-family: "Poppins", sans-serif;
}
.NextCity h3 {
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  color: #28292d;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.NextCity p {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #585858;
  font-family: "Poppins", sans-serif;
}
.NextCityList {
  margin-top: 50px;
}
.NextCity .cityelm {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.NextCity .cityelmImg {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}
.NextCity .cityelmImg img {
  width: 70%;
}
.NextCity .cityelmImg h6 {
  font-weight: 500;
  font-size: 18px;
  color: #595c62;
  margin: 0;
  margin-top: 20px;
}
.btnbadge {
  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #595c62;
  border: 0.5px solid #595c62;
  border-radius: 20px;
  margin: 0 auto;
  padding: 2px 13px;
  cursor: pointer;
  margin-top: 10px;
}
.btnbadge.active {
  border: 2px solid #3f3fb3;
}
.btnbadge .icon {
  margin-right: 10px;
}
.addcityelm img {
  cursor: pointer;
}
@media only screen and (max-width: 960px) {
  .NextCity .cityelm {
    width: 70%;
  }
  .NextCity .cityelmImg img {
    width: 90%;
  }
  .NextCity {
    padding-top: 0;
  }
  .NextCity h3 {
    font-size: 30px;
  }

  .NextCity .cityelmImg h6 {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .NextCity h3 {
    font-size: 24px;
  }
  .NextCity p {
    font-size: 12px;
  }
  .NextCity .cityelmImg h6 {
    font-size: 12px;
  }
  .NextCity .cityelmImg img {
    width: 70%;
  }
  .NextCity{
    padding: 50px 0;
  }
}