.MathiqFeature {
  background-image: url("../assets/images/mathif_vecter_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.MathiqFeature .row {
  align-items: center;
}
.MathiqFeature .img_wrap video {
  width: 100%;
  border-radius: 10px;
}
.MathiqFeature ul {
  list-style: none;
  padding: 0;
  margin-top: 60px;
}
.MathiqFeature ul li:not(:last-child) {
  margin-bottom: 20px;
}
.MathiqFeature .content {
  margin-left: 50px;
}
.MathiqFeature .content h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #28292d;
}
.MathiqFeature .content p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #595c62;
  margin: 0;
  margin-top: 40px;
}
.MathiqFeature .content ul li {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #28292d;
  padding-left: 90px;
  margin-bottom: 40px;
}
.MathiqFeature .content ul li::before {
  content: "";
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.MathiqFeature .content ul li:nth-child(1):before {
  background-image: url("../assets/images/Group 2417.png");
}
.MathiqFeature .content ul li:nth-child(2):before {
  background-image: url("../assets/images/Group 2418.png");
}
.MathiqFeature .content ul li:nth-child(3):before {
  background-image: url("../assets/images/Group 2419.png");
}
.MathiqFeature .content ul li:nth-child(4):before {
  background-image: url("../assets/images/Group 2420.png");
}
@media only screen and (max-width: 960px) {
  .MathiqFeature ul {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
  }
  .MathiqFeature .row {
    flex-direction: column-reverse;
  }
  .MathiqFeature{
    background: none;
  }
  
  .MathiqFeature .content {
    margin-left: 0;
  }
  .MathiqFeature .content h3 {
    font-size: 30px;
    text-align: center;
  }
  .MathiqFeature .content ul li {
    font-size: 16px;
  }
  .MathiqFeature .img_wrap video {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  .MathiqFeature .content p {
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {  
  .MathiqFeature .row {
    flex-direction: column-reverse;
  }
  .MathiqFeature .content p {
    text-align: left;
    font-size: 12px;
    margin-top: 20px;
  }
  .MathiqFeature .content h3 {
    font-size: 24px;
  }
  .MathiqFeature .content ul li::before {
   width: 70px;
   height: 70px;
  }
  .MathiqFeature ul{
    margin-top: 30px;
  }
}