.toggleButton{
    display: none;
}
.close_btn{
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
}
.close_btn img{
    width: 20px;
}
nav .log_wrap{
    padding: 20px 0;
}
nav .rectVecter{
    position: absolute;
    left: 50%;
    height: 100px;
    transform: translateX(-70%);
}
nav {
    display: flex;
    justify-content: space-between;
}
nav .link_menu{
    list-style: none;
    display: flex;
}
.link_menu  a{
    padding: 20px 20px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #373737;  
    
}
.geniushunt_flyer{
    position: relative;
    margin-top: 80px;
}
.geniushunt_flyer .content .typewrite{
    padding: 8px 10px;
    background: #DDF7FF;
    display: inline-block;
    border-radius: 8px;
    margin-top: 10px;
    min-width: 350px;
}
.geniushunt_flyer .content .typewrite h3{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    color: #4647C6;
    display: inline-block;
}
.geniushunt_flyer .content h2{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    color: #28292D;
}
.geniushunt_flyer .content p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #28292D;
    padding-top: 40px;
    padding-bottom: 30px;
    margin: 0;
}
/* .geniushunt_flyer .geniushuntVecter_img_wrap{
    position: relative;
} */
.geniushunt_flyer .geniushuntVecter_img_wrap img.devide_vector_icon{
    position: absolute;
    top: -60px;
    width: 50px;
    right: 20px;
}
.geniushunt_flyer .geniushuntVecter_img_wrap img.vecter_img{
    position: absolute;
    right: 0;
    max-width: 600px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}
.geniushunt_flyer .name_tag{
    position: absolute;
    right: 86px;
    top: 25px;
}
.geniushunt_flyer .name_tag_elm{
    display: inline-block;
    background: #0085FF;
    border-radius: 80px;
    padding: 8px 20px;
    color: #ffff;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 1;

}
.geniushunt_flyer .name_tag_elm span{ 
    display: inline-block; 
    font-weight: 400;
    font-size: 13px;
    padding-left: 20px; 
}
.geniushunt_flyer .subject_rank{ 
    display: inline-block;
    background: #FFD330;
    border-radius: 80px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    padding: 8px 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #28292D;
    position: absolute;
    bottom: -14px;
    right: -18px;
}
@media only screen and (max-width: 960px) {
    nav .rectVecter{       
        height: 50px;
        left: 48%;
    }
    .geniushunt_flyer .geniushuntVecter_img_wrap img.vecter_img{       
        max-width: 400px;      
    }
    .geniushunt_flyer .content h2{
        font-size: 35px;
    }
    .geniushunt_flyer .content .typewrite h3{
        font-size: 40px;
    }
    .geniushunt_flyer .name_tag_elm{
        font-size: 16px;
        text-align: left;
    }
    .geniushunt_flyer .subject_rank{
        font-size: 13px;
    }
    .geniushunt_flyer .name_tag{
        right: 21px;
        top: 50px;
    }
    .geniushunt_flyer .name_tag_elm span{
        padding: 0;
    }
    
    .geniushunt_flyer .geniushuntVecter_img_wrap img.devide_vector_icon{       
        top: -100px;
        right: 30px;
        width: 30px;
    }
    .geniushunt_flyer .geniushuntVecter_img_wrap{
        display: flex;
        justify-content: end;
        align-items: flex-end;
        position: relative;
    }
    .geniushunt_flyer .geniushuntVecter_img_wrap img.vecter_img{
        position: relative;
        right: 0;
        max-width: 600px;
        width: 100%;
        top:0;
        transform: none;
    }
    .geniushunt_flyer .name_tag{
        position: absolute;
        right: 10%;
        top: 20%;
        bottom: auto;
    }
    .geniushunt_flyer .name_tag_elm{
        font-size: 13px;
        padding: 8px 15px;  
        text-align: left;  
    }
    .geniushunt_flyer .subject_rank{      
        font-size: 11px;
        bottom: -13px;
        right: -9px;
        padding: 5px 8px;
    }
    .geniushunt_flyer .name_tag_elm span{
        padding: 0;
    }
    .geniushunt_flyer .content .typewrite h3{
        font-size: 30px;
        text-align: center;
    }
    .geniushunt_flyer .content h2{        
        font-size: 40px;
        text-align: center;
    }
    .geniushunt_flyer .content{
        text-align: center;
        margin-bottom: 30px;
    }
    .geniushunt_flyer .content .typewrite h3{       
        font-size: 30px;
    }
    .geniushunt_flyer .content h2{        
        font-size: 20px;
    }
    .geniushunt_flyer{
        margin-top: 0;
    }
    .log_wrap img{height: 55px;}
    .toggleButton{
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .toggleButton img{
        width: 20px;
    }
    nav .link_menu{
        display: flex;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #FFFFFF;
        z-index: 99;
        
    }
    nav .link_menu a{
        display: block;
    }
    nav .link_menu.active{
        display: flex!important;
    } 
    .close_btn{
        display: block;
    }
}
@media only screen and (max-width: 960px) {
    .geniushunt_flyer .content .typewrite{
        min-width: 250px;
    }
}