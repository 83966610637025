.RecentHeighlights {
  padding-top: 60px;
  margin-top: 60px;
  background-image: url("../assets/images/recentheighlight_bg.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}
.imgpopup .modal-content{
  background-color: transparent;
}
.particepent_details_row h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #28292d;
  overflow: visible;
  margin-bottom: 20px;
}
.particepent_gorup p {
  font-family: "Poppins", sans-serif;
  margin: 0;
  margin-left: 15px;
}
.particepent_details .particepent_details_elm {
  position: relative;
  padding-left: 15px;
  text-align: left;
}
.particepent_details .particepent_details_elm::before {
  content: "";
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #4647c6;
}
.particepent_details .particepent_details_elm h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #595c62;
}
.particepent_details_elm h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #28292d;
}
.particepent_details {
  margin-top: 50px;
}
.gallery_wrap img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
.particepent_details_row {
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.particepent_details_row .img_wrap {
  overflow: hidden;
  border-radius: 8px;
  /* padding: 5px; */
  width: 100%;
}
.gallery_wrap * {
  overflow: hidden;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
}
.viewmore {
  position: relative;
}
.btn_viewmore {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.788);
  top: 0;
  left: 0;
}
.btn_viewmore a {
  font-size: 14px;
  color: #4647c6;
  text-decoration: underline;
  font-weight: 500;
}
.ReactPhoneInput{
  height: 40px;
  width: 100%;
}
.viewmore_wraper{
  text-align: center;
  margin-top: 20px;
}
.viewmore_wraper a{
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  color: #4647C6;
  display: inline-block;

}
@media only screen and (max-width: 960px) {
  .content{
    padding-right: 20px;
  }
  .RecentHeighlights {
    padding-bottom: 50px;
    background-size: contain;
  }
  .particepent_details_row h3 {
    text-align: center;
    margin-bottom: 30px;
  }
 
  .particepent_gorup {
    margin-top: 30px;
  }
  .gallery_wrap {
    margin-top: 30px;
  }
  .particepent_details_row .content * {
      margin-right: 0!important;
      font-family: "Poppins", sans-serif;
  }
}
@media only screen and (max-width: 600px) {
  .group_img{
    height: 40px;
  }
  .particepent_details_row h3 {
    font-size: 24px;
    
  }
  .particepent_details_row p {
    font-size: 12px;
  }
  .particepent_details_elm h5 {
    font-size: 12px;
  }
  .particepent_details .particepent_details_elm h6 {
      font-size: 12px;
      margin-bottom: 6px;
  }
 
}
