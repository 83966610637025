.MathiqGame {
  margin: 100px 0;
}
.content h5 img {
  display: inline-block;
  height: 30px;
  margin: 0 8px;
}
.content h3 {
  display: inline-block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 64px;
  color: #28292d;
}

.content p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #595c62;
  margin: 0;
  margin-top: 40px;
}
.content h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #25bec1;
  margin-top: 30px;
}
.applogo {
  display: inline-block;
  margin-top: 30px;
}
.applogo img {
  height: 50px;
}

.iframe_wrap iframe {
  height: 100%;
  width: 100%;
  max-width: 426px;
  /* overflow: hidden; */
  background: #fff;
  box-shadow: 0 2.24078px 22.4078px rgb(0 0 0 / 30%);
  border-radius: 8.96311px;
  height: 100%;
  height: 680px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
}
.iframe_wrap {
  position: relative;
}
.iframe_wrap::before {
  content: "";
  position: absolute;
  left: -80%;
  top: 30%;
  display: inline-block;
  width: 208px;
  height: 100px;
  background-image: url("../assets/images/Vector 291.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.iframe_wrap iframe body {
  height: 600px !important;
  overflow: hidden;
}
@media only screen and (max-width: 960px) {
  .iframe_wrap::before {
    display: none;
  }
}
@media only screen and (max-width: 960px) {
  .MathiqGame .content {
    margin-bottom: 30px;
    text-align: center;
  }
  .MathiqGame {
    position: relative;
    padding-bottom: 200px;
    margin-bottom: 0;
  }
  .content h3 {
    font-size: 30px;
  }
  .content p {
    margin-top: 0;
  }
  .contentFooter {
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 960px) {
  .content h3 {
    font-size: 24px;
    line-height: 1.5;
  }
  .content p {
    font-size: 12px;
  }
  .content h5 {
    font-size: 16px;
    line-height: 1.5;
  }
  .content h5 img {
    height: 25px;
    margin: 0 4px;
  }
  .applogo img {
    height: 40px;
  }
}