.registerform {
  margin: 100px 0;
  position: relative;
}
.img_wrap img {
  width: 100%;
}

.formWrap.text.text-danger {
  position: absolute;
  bottom: 45px;
}
.formWrap h4 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #28292d;
  margin-bottom: 20px;
}
.formWrap h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: #4647c6;
  margin-bottom: 40px;
}
.formWrap label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #797f89;
}
.formWrap input {
  border-radius: 0;
}
.formWrap input::placeholder {
  color: #B4BBC6;
}

@media only screen and (max-width: 960px) {
  .formWrap input {
    border-radius: 8px;
  }
  .registerform {
    position: relative;
    padding-top: 130px;
    margin: 50px 0;
  }
  .formWrap {
    padding: 0;
  }
  .title_wrap {
    position: absolute;
    top: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
  .formWrap h3 {
    font-size: 25px;
  }
  .formWrap form {
    margin-top: 30px;
  }
  .registerform .btn_wrap {
    text-align: center;
  }
  .img_wrap img {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  .formWrap label {
    font-size: 13px;
  }
}

@media only screen and (max-width: 960px) {
  .formWrap h4 {
    font-size: 16px;
    display: inline;
  }
  .formWrap h3 {
    font-size: 16px;
    display: inline-block;
    margin-left: 6px;
  }
  .registerform {
    padding-top: 85px;
  }
  
  
}