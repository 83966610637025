.header_section {
    padding: 50px 0;
    background: linear-gradient(180deg, rgba(72, 238, 218, 0) 0%, #48EEDA 100%);
}
.header_section .content{
    padding-right: 20px;
}
.header_section .content h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    color: #28292D;
}
.header_section .content p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #595C62;
    margin: 25px 0;
}
.header_section img{
    max-width: 600px;
    width: 100%;
}
/* blog card */
.BlogCard{
    width: 100%;
} 
.BlogCard .content{
    margin-top: 15px;
}
.BlogCard img{
    border-radius: 16px;
    width: 100%;
}
.BlogCard h6{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;    
    color: #595C62;
}
.BlogCard h5{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #28292D;
}
.BlogCard p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #595C62;
    margin: 25px 0;
}
.BlogCard a{
    text-decoration: underline;
    color: #4647C6;
    
}
/* BlogArticle */
.BlogArticle .article_img img{
    width: 100%;
}
.BlogArticle .content h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    color: #28292D;
    margin-top: 100px;
}
.BlogArticle .content h6{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #595C62;
    margin: 30px 0;
}
.BlogArticle .content p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #595C62;
    text-align:justify;
    margin-bottom: 80px;
}